











































import { Component, Vue } from 'vue-property-decorator'

import Navigation from '@/views/admin/Navigation.vue'

@Component({
    components: {
        Navigation
    }
})
export default class AdminMain extends Vue {
  
}
