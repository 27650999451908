






































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Navigation extends Vue {
  email = ''
  role = ''

  items = [
          { title: 'Home', icon: 'mdi-home', path: '/admin' },
          { title: 'Gebruiker toevoegen', icon: 'mdi-account-multiple-plus', path: '/admin/create-user' },
          { title: 'Model toevoegen', icon: 'mdi-pencil-outline', path: '/admin/create-model' }
      ]

  mounted(): void {
      this.email = this.$store.getters['identity/email'] ?? 'test@example.com'
      this.role = this.$store.getters['identity/role'] ?? 'none'
  }
}
